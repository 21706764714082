import { create } from 'zustand'
import { uniqBy } from 'lodash-es'
import { LocalStorageService } from '../services/local-storage'

export interface IBreed {
  id: number
  name: string
}

export interface IHorse {
  id: number
  name: string
  breeds: IBreed[]
}

export interface IPackage {
  groups: {id: number, name: string}[]
  id: number
  isBundle: boolean
  name: string
  description: string
  price: number
  priorityOrder: number
  reportTypes: {id: number, name: string}[]
}

export interface IOrderHorse extends IHorse {
  packages: IPackage[]
}

export type TCheckoutState = {
  __reset: () => void
  allHorses: IHorse[]
  orderHorses: IOrderHorse[]
  paymentMethod: string
  orderId: string
  couponId: number
  amount: number
  currentHorse?: IOrderHorse
  clientSecret: string
  buyerToken: string
  setAllHorses: (allHorses: IHorse[]) => void
  /**
   * Add a horse to the order
   * @param orderHorse
   */
  addOrderHorse: (orderHorse: IOrderHorse) => void
  setPaymentMethod: (paymentMethod: string) => void
  setCouponId: (couponId: number) => void
  setOrderId: (orderId: string) => void
  setAmount: (amount: number) => void
  setCurrentHorse: (horseOrId?: number | IHorse) => void
  setHorsePackages: (tests: IPackage[], orderHorseId?: number) => void
  setClientSecret: (clientSecret: string) => void
  setBuyerToken: (buyerToken: string) => void
  /**
   * Toggles a test from an order horse.
   *
   * @param {IPackage} test The test to toggle from the order horse.
   * @param {number} [orderHorseId] The ID of the order horse. Optional, if not provided then inferred it's the currentHorse
   * @returns {void}
   */
  toggleHorsePackagesTest: (test: IPackage, orderHorseId?: number) => void
}

const DEFAULT_CHECKOUT_STATE = {
  allHorses: [],
  orderHorses: [],
  paymentMethod: '',
  couponId: NaN,
  orderId: '',
  clientSecret: '',
  buyerToken: '',
  amount: 0,
  currentHorse: undefined,
}

export const useCheckoutState = create<TCheckoutState>((set, get) => ({
  ...(LocalStorageService.get('@@checkoutState') || structuredClone(DEFAULT_CHECKOUT_STATE)),
  __reset: () => set(structuredClone(DEFAULT_CHECKOUT_STATE)),
  setAllHorses: (allHorses: IHorse[]) => set({ allHorses }),
  addOrderHorse: (orderHorse: IOrderHorse) => {
    const foundOrderHorse = get().orderHorses.find((horse) => horse.id === orderHorse.id)

    // Don't add the same horse twice
    if (foundOrderHorse) {
      // merge the packages
      const orderHorses = get().orderHorses.map((horse) => {
        if (horse.id === orderHorse.id) {
          return {
            ...horse,
            packages: uniqBy([...horse.packages, ...orderHorse.packages], 'id'),
          }
        }
        return horse
      })

      set({ orderHorses })
    } else {
      const orderHorses = [...get().orderHorses, orderHorse]

      set({ orderHorses })
    }
  },
  setPaymentMethod: (paymentMethod: string) => set({ paymentMethod }),
  setOrderId: (orderId: string) => set({ orderId }),
  setCouponId: (couponId: number) => set({ couponId }),
  setAmount: (amount: number) => set({ amount }),
  setClientSecret: (clientSecret: string) => set({ clientSecret }),
  setBuyerToken: (buyerToken: string) => set({ buyerToken }),
  setCurrentHorse: (horseOrId?: number | IHorse) => {
    if (!horseOrId) return set({ currentHorse: undefined })

    const horse = typeof horseOrId === 'number' ? get().allHorses.find((horse) => horse.id === horseOrId) : horseOrId

    if (horse) {
      const orderHorse = get().orderHorses.find((_horse) => horse.id === _horse.id) || {
        ...horse,
        packages: [],
      }
      get().addOrderHorse(orderHorse)
      set({ currentHorse: orderHorse })
    } else {
      set({ currentHorse: undefined })
    }
  },
  setHorsePackages: (tests: IPackage[], orderHorseId?: number) => {
    const { currentHorse, orderHorses } = get()

    // if no orderHorseId is provided, use the currentHorse
    const isCurrentHorse = !orderHorseId || currentHorse?.id === orderHorseId
    const horse = !isCurrentHorse ? orderHorses.find((horse) => horse.id === orderHorseId) : currentHorse

    if (!horse) {
      console.error('checkoutState#toggleHorsePackagesTest - No horse found')
      return
    }

    horse.packages = [...tests]

    const updatedHorse = structuredClone(horse)

    // update the current horse and order horses
    set({
      currentHorse: isCurrentHorse ? updatedHorse : currentHorse,
      orderHorses: orderHorses.map((_horse) => (_horse.id === horse.id ? updatedHorse : _horse)),
    })
  },
  toggleHorsePackagesTest: (test: IPackage, orderHorseId?: number) => {
    const { currentHorse, orderHorses } = get()

    // if no orderHorseId is provided, use the currentHorse
    const isCurrentHorse = !orderHorseId || currentHorse?.id === orderHorseId
    const horse = !isCurrentHorse ? orderHorses.find((horse) => horse.id === orderHorseId) : currentHorse

    if (!horse) {
      console.error('checkoutState#toggleHorsePackagesTest - No horse found')
      return
    }

    const foundTest = horse.packages.find((t) => t.id === test.id)

    if (foundTest) {
      horse.packages = horse.packages.filter((t) => t.id !== test.id)
    } else {
      horse.packages = [...horse.packages, test]
    }

    const updatedHorse = structuredClone(horse)

    // update the current horse and order horses
    set({
      currentHorse: isCurrentHorse ? updatedHorse : currentHorse,
      orderHorses: orderHorses.map((_horse) => (_horse.id === horse.id ? updatedHorse : _horse)),
    })
  },
}))

useCheckoutState.subscribe((state) => {
  console.log('Checkout state updated:', state)

  LocalStorageService.set('@@checkoutState', useCheckoutState.getState())
})
