import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Badge,
  Button,
  Input,
  Separator,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@edx/react-common'
import horseIconSvg from '../assets/horse-icon.svg'
import { Alert, Spinner } from 'flowbite-react'
import currencyFormatter from '../utils/currencyFormatter'
import { MdRemoveCircleOutline } from 'react-icons/md'
import { useCheckoutState } from '../state/checkout'
import { useNavigate } from 'react-router-dom'
import { useOrdersCheckCoupon } from '../hooks/useOrdersCheckCoupon'
import { IOrderHorseCouponPackage } from '../context/form-context'
import { HiInformationCircle } from 'react-icons/hi'
import { usePrevious } from 'ahooks'

interface OrderReviewProps {}

function OrderReviewAside({}: OrderReviewProps) {
  const { orderHorses, setCouponId, toggleHorsePackagesTest, setAmount } = useCheckoutState()
  const navigate = useNavigate()
  const [coupon, setCoupon] = useState('')
  const [isCouponFrozen, setCouponFrozen] = useState(false)
  const [discountAmount, setDiscountAmount] = useState(-1)
  const [isCouponApplying, setCouponApplying] = useState(false)
  const [couponError, setCouponError] = useState(false)
  const checkOrdersCoupon = useOrdersCheckCoupon()
  const [accordianCollapseValue, setAccordianCollapseValue] = useState(orderHorses.map((horse) => horse.id.toString()))

  const previousOrderHorses = usePrevious(orderHorses)

  useEffect(() => {
    const orderHorseIds = orderHorses.map((horse) => horse.id)
    const previousOrderHorseIds = previousOrderHorses?.map((horse) => horse.id) ?? []

    //   if a new orderHorseId is added, then add it to the accordianCollapseValue
    if (orderHorseIds.length > previousOrderHorseIds.length) {
      setAccordianCollapseValue([...accordianCollapseValue, orderHorseIds[orderHorseIds.length - 1].toString()])
    }
  }, [orderHorses])

  const [orderTotalPrice, itemsInCart] = orderHorses.reduce(
    (total, items) => {
      return [
        items.packages?.reduce((tt, items1) => items1.price + tt, total[0]) || 0,
        total[1] + (items.packages?.length || 0),
      ]
    },
    [0, 0],
  )

  useEffect(() => {
    setAmount(orderTotalPrice)
  }, [orderTotalPrice])

  const onSubmit = () => {
    navigate('/checkout')
  }

  const onApplyCoupon = async () => {
    if (!coupon) return
    try {
      setCouponApplying(true)
      const { data: response } = await checkOrdersCoupon({
        code: coupon,
        horses: orderHorses.map((horse) => {
          return {
            id: horse.id,
            packages: horse.packages.map<IOrderHorseCouponPackage>((p) => {
              return { id: p.id }
            }),
          }
        }),
      })
      setCouponFrozen(true)
      setCouponApplying(false)
      const { discountType, data } = response
      if (discountType === 'Coupon') {
        const { id, type, amount, packages: couponPackages } = data
        let totalPrice = 0
        let totalDiscountItemPrice = 0
        for (const order of orderHorses) {
          for (const pkg of order.packages) {
            totalPrice += pkg.price
            if (couponPackages.indexOf(pkg.name) >= 0) {
              totalDiscountItemPrice += pkg.price
            }
          }
        }
        if (type === 'Percentage')
        {
          let discountAmount = Number(Math.round(totalDiscountItemPrice * amount / 100.00).toFixed(2));
          totalPrice -= discountAmount;
        }
        else totalPrice -= Math.min(totalDiscountItemPrice, amount)
        setCouponId(id)
        setDiscountAmount(totalPrice)
        setAmount(totalPrice)
        setCouponError(false)
      }
    } catch (err) {
      setCouponApplying(false)
      setCouponError(err.response.errors[0].description)
    }
  }

  return (
    <div className="h-full flex flex-col">
      <div className="h-full overflow-y-scroll pb-6">
        {!itemsInCart && (
          <div className="flex items-center justify-center h-full">
            <span className="text-gray-600">No items in cart</span>
          </div>
        )}
        <Accordion
          type="multiple"
          className="w-full"
          onValueChange={setAccordianCollapseValue}
          value={accordianCollapseValue}
        >
          {orderHorses
            .filter((horse) => horse.packages.length)
            .map((horse) => (
              <AccordionItem key={horse.id} value={horse.id.toString()}>
                <AccordionTrigger className="py-1">
                  <div className="flex h-11 items-center justify-between rounded-lg w-full">
                    <div className="flex space-x-2 items-center">
                      <img src={horseIconSvg} alt="Etalon Customer Horse" />
                      <span className="my-auto ml-4 max-w-[14rem] overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold text-gray-600">
                        {horse.name}
                      </span>
                    </div>

                    <div className="mr-2">
                      <Badge variant="outline">{horse.packages.length}</Badge>
                    </div>
                  </div>
                </AccordionTrigger>

                <AccordionContent>
                  <div className="text-black">
                    {horse.packages.length === 0 && <Badge color="failure">No Tests Selected</Badge>}
                    {horse.packages.length > 0 && (
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableCell>Test</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {horse.packages.map((test) => (
                            <TableRow key={test.id}>
                              <TableCell>{test.name}</TableCell>
                              <TableCell className="">{currencyFormatter.format(test.price / 100)}</TableCell>
                              <TableCell className="flex justify-end">
                                <Button
                                  className=" rounded-full"
                                  variant="ghost"
                                  onClick={() => toggleHorsePackagesTest(test, horse.id)}
                                >
                                  <MdRemoveCircleOutline />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
        </Accordion>
      </div>

      <Separator className="mt-2 mb-4" />

      <div className="flex justify-between items-center space-x-2 py-3 flex-grow-0">
        <div className="text-xl font-bold flex justify-between w-full truncate">
          Order Total:&nbsp;
          {discountAmount < 0 && (
            <span className="text-edxred-500">{currencyFormatter.format(Number(orderTotalPrice) / 100)}</span>
          )}
          {discountAmount >= 0 && (
            <>
              <span className="text-edxred-500 line-through">
                {currencyFormatter.format(Number(orderTotalPrice) / 100)}
              </span>
              &nbsp;
              <span className="text-edxgrey-500">{currencyFormatter.format(Number(discountAmount) / 100)}</span>
            </>
          )}
        </div>

        <Button onClick={onSubmit} variant="brand">
          Checkout
        </Button>
      </div>

      <div className="">
        <div className="mt-2 flex grow-[2] columns-2 gap-x-2">
          <Input
            type="text"
            placeholder="Coupon Code"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
            disabled={isCouponFrozen || isCouponApplying || !orderHorses.find((horse) => horse.packages.length > 0)}
          />
          {!isCouponFrozen && (
            <Button variant="outline" onClick={onApplyCoupon} disabled={isCouponApplying || !coupon}>
              Apply
              {isCouponApplying && (
                <>
                  &nbsp;
                  <Spinner className="fill-edxred-500" size="sm" aria-label="Center-aligned spinner" />
                </>
              )}
            </Button>
          )}
        </div>
        {couponError && (
          <Alert color="failure" icon={HiInformationCircle}>
            <span className="font-medium">{couponError}</span>
          </Alert>
        )}
      </div>
    </div>
  )
}

OrderReviewAside.defaultProps = {}

export { OrderReviewAside }
