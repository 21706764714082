import * as React from 'react'
import { Check, ChevronsUpDown } from 'lucide-react'
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  cn,
  Popover,
  PopoverContent,
  PopoverTrigger,
  CommandList,
  Badge,
} from '@edx/react-common'
import { useResizeObserver } from 'usehooks-ts'
import { useMemo, useRef } from 'react'
import { useHorses } from '../hooks/useHorses'
import horseIconSvg from '../assets/horse-icon.svg'
import { IOrderHorse, useCheckoutState } from '../state/checkout'
import { FaPlus } from 'react-icons/fa'

type Props = {
  buttonClassName?: string
  onSelectAddNewHorse: () => void
}

const HorseOrdersSubText = ({ horseId, orderHorses }: { horseId: number; orderHorses: IOrderHorse[] }) => {
  const orderHorse = orderHorses.find((orderHorse) => orderHorse.id === horseId)

  if (orderHorse?.packages?.length) {
    return (
      <div className="flex justify-center items-center space-x-2">
        {' '}
        <Badge variant="default">{orderHorse.packages.length}</Badge>
        <span>Test{orderHorse.packages.length > 1 ? 's' : ''} in cart</span>
      </div>
    )
  }

  return null
}

export function HorsesComboBox({ buttonClassName, onSelectAddNewHorse }: Props) {
  const { allHorses, currentHorse, orderHorses, setCurrentHorse } = useCheckoutState()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const { width: buttonWidth = 0 } = useResizeObserver({
    ref: buttonRef,
    box: 'border-box',
  })

  const [open, setOpen] = React.useState(false)

  // todo: this is only here to trigger the fetch of data
  useHorses({ Filter: '' })

  const horsesById = useMemo(() => {
    return allHorses.reduce(
      (acc, horse) => {
        acc[horse.id] = horse
        return acc
      },
      {} as Record<number, any>,
    )
  }, [allHorses])

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn('bg-muted h-10 w-[300px] justify-between', buttonClassName)}
          ref={buttonRef}
        >
          {currentHorse ? (
            <div className="flex space-x-2">
              <img src={horseIconSvg} alt="Silhouette of a horses' head" />
              <span className="my-auto ml-4 max-w-[14rem] overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold text-gray-600">
                {currentHorse.name}
              </span>
            </div>
          ) : (
            'Select horse...'
          )}

          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className={cn('w-[300px] p-0')} style={{ width: buttonWidth }}>
        <Command
          filter={(value, search) => {
            return horsesById[value]?.name.toLowerCase().includes(search.toLowerCase()) ? 1 : 0
          }}
        >
          {allHorses.length >= 5 && <CommandInput placeholder="Search horses..." />}

          <CommandEmpty>No horses found.</CommandEmpty>

          <CommandGroup>
            <CommandList>
              <CommandItem
                key={1}
                onSelect={(currentValue) => {
                  onSelectAddNewHorse()
                  setOpen(false)
                }}
              >
                <div className="flex space-x-2 p-2 items-center justify-center text-lg">
                  <FaPlus /> <span>Add New Horse</span>
                </div>
              </CommandItem>

              {allHorses.map((horse) => (
                <CommandItem
                  className="py-1.5 cursor-pointer"
                  key={horse.id}
                  value={horse.id.toString()}
                  onSelect={(currentValue) => {
                    setCurrentHorse(+currentValue)
                    setOpen(false)
                  }}
                >
                  <Check className={cn('mr-2 h-4 w-4', currentHorse?.id === horse.id ? 'opacity-100' : 'opacity-0')} />

                  <div className="flex flex-col">
                    <div className="font-semibold text-base">{horse.name}</div>

                    <div className="text-gray-600 text-sm">
                      <HorseOrdersSubText horseId={horse.id} orderHorses={orderHorses} />
                    </div>
                  </div>
                </CommandItem>
              ))}
            </CommandList>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
