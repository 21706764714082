import * as React from 'react'
import { Button } from '@edx/react-common'
import { useAuthState } from '../state/auth'
import { useCheckoutState } from '../state/checkout'

export function ErrorBoundaryFallback(props: {
  error: Error
  componentStack: string
  eventId: string
  resetError(): void
}): React.ReactElement {
  const authState = useAuthState()
  const checkoutState = useCheckoutState()

  const SuggestedIssue = () => {
    if (props.error?.message?.includes?.("Failed to execute 'removeChild' on 'Node'")) {
      return (
        <p className="font-semibold">
          We&apos;ve noticed that this type of error is thrown when your browser&apos;s Translate Page feature is in
          use.
          <br />
          It might be worth trying another browser, another translation tool, or disable the translation feature on this
          page.
        </p>
      )
    }

    return null
  }

  return (
    <div className="flex flex-col items-center justify-center h-full bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-4 bg-white rounded-lg shadow-md ">
        <h2 className="text-2xl font-semibold text-center text-gray-700 ">
          Oops! This is embarassing, an error has occurred.
        </h2>

        <p className="text-gray-500 ">
          We apologize for the inconvenience. You can try again. If this problem persists please reach out to our team:
          <p className="text-center">
            <a className="text-blue-500 text-lg" href="tel:+1-650-380-2995">
              +1-650-380-2995
            </a>{' '}
            |{' '}
            <a className="text-blue-500 text-lg" href="mailto:info@etalondx.com">
              info@etalondx.com
            </a>
          </p>
        </p>

        <SuggestedIssue />

        <Button
          className="w-full"
          onClick={() => {
            authState.__reset()
            checkoutState.__reset()
            props.resetError()
          }}
          variant="secondary"
        >
          Start Over
        </Button>
      </div>
    </div>
  )
}
