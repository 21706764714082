import React from 'react'
import { Spinner } from 'flowbite-react'
import { useHorses } from '../hooks/useHorses'
import { IHorse } from '../context/form-context'
import { Heading } from '../components/Heading'
import { ScreenLayout } from '../components/ScreenLayout'
import { Button } from '../components/Button'
import horseIconSvg from '../assets/horse-icon.svg'
import { FaChevronRight, FaPlus } from 'react-icons/fa'
import { SubHeading } from '../components/SubHeading'
import { useCheckoutState } from '../state/checkout'
import { useElementDimensions } from '@edx/react-common'
import {useNavigate} from "react-router-dom";

const HorseSelection = (props: { onSelectHorse: (horse: IHorse) => void }) => {
  const { allHorses, setCurrentHorse, addOrderHorse } = useCheckoutState()
  const { isLoading } = useHorses({ Filter: '' })
  const { ref: containerRef, heightToBottomOfScreen } = useElementDimensions()
    const navigate = useNavigate()

  return (
    <ScreenLayout>
      <Heading>Select a Horse</Heading>

      <SubHeading>Select a horse to order tests or add a new horse</SubHeading>

      <div
        className="form-container flex flex-col "
        ref={containerRef as any}
        style={{ height: `${heightToBottomOfScreen}px` }}
      >
        <div id="horse-selection-wrapper" className="my-4 overflow-scroll">
          {isLoading && (
            <div className="mt-12 text-center flex flex-col items-center justify-center">
              <Spinner className="fill-edxred-500" size="lg" aria-label="Center-aligned spinner" />
              <p className="whitespace-pre-wrap">Loading your horses...</p>
            </div>
          )}

          <div className="flex flex-col space-y-2">
          {allHorses.map((horse) => (
              <div
                className="cursor-pointer flex items-center justify-between bg-gray-300/50 hover:bg-gray-300 rounded p-2"
                key={horse.id}
                onClick={() => {
                  setCurrentHorse(horse)
                  props.onSelectHorse(horse)
                }}
              >
                <div className="flex space-x-2 items-center">
                  <img src={horseIconSvg} alt="Silhouette of a horses' head" />
                  <span className="my-auto ml-4 max-w-[14rem] overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold text-gray-600">
                    {horse.name}
                  </span>
                </div>

                <FaChevronRight />
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-2">
          <Button className="w-full" outline onClick={() => navigate('/add-horse')}>
            <FaPlus className="mr-2" /> <span>Add a New Horse</span>
          </Button>
        </div>
      </div>
    </ScreenLayout>
  )
}

export default HorseSelection
